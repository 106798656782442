import React, { useState } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import teamStyles from "./meet-the-team.module.css";
import TeamSection from "../components/TeamSection";

import { useStaticQuery, graphql, Link } from "gatsby";

const TeamPage = (props) => {
  // find unqiue categories
  //   const uniqueCats = [];
  //   allWordpressWpTeammember.edges
  //     .slice(0)
  //     .reverse()
  //     .map(({ node }) => {
  //       if (uniqueCats.indexOf(node.acf.category) === -1) {
  //         uniqueCats.push(node.acf.category);
  //       }
  //     });

  return (
    <Layout>
      <SEO title="Meet the Team" />
      <div className="container container--page container--team">
        <TeamSection category="Partners" />
        <TeamSection category="Professionals" />
        <TeamSection category="Advisors" />
      </div>
    </Layout>
  );
};

export default TeamPage;
