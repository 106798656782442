import React, { useState } from "react";
import teamStyles from "../pages/meet-the-team.module.css";
import Img from "gatsby-image";
import { useStaticQuery, graphql, Link } from "gatsby";

const TeamSection = (props) => {
  const { allWordpressWpTeammember } = useStaticQuery(
    graphql`
      query TeamQuery {
        allWordpressWpTeammember {
          edges {
            node {
              title
              id
              content
              slug
              acf {
                category
                order_in_category
                headshot {
                  localFile {
                    childImageSharp {
                      fixed(width: 200, height: 300) {
                        ...GatsbyImageSharpFixed
                      }
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }

                credentials
              }
            }
          }
        }
      }
    `
  );

  return (
    <>
      <h1 className={`center team__title ${teamStyles.h1}`}>
        {props.category}
      </h1>
      <div className={`flex__wrapper ${teamStyles.team__member}`}>
        {allWordpressWpTeammember.edges
          .sort(
            (a, b) =>
              a.node.acf.order_in_category - b.node.acf.order_in_category
          )
          .map(({ node }) => {
            if (node.acf.category === props.category) {
              return (
                <Link
                  className={`flex__item ${teamStyles.link}`}
                  to={`/bio/${node.slug}`}
                >
                  {/* //   <img src={node.acf.headshot.source_url} alt={node.title} /> */}

                  {node.acf.headshot.localFile && (
                    <Img
                      alt=""
                      fixed={node.acf.headshot.localFile.childImageSharp.fixed}
                    />
                  )}
                  <span className={teamStyles.hover_text}>
                    Meet {node.title}
                  </span>
                  <h2
                    className={teamStyles.h2}
                    dangerouslySetInnerHTML={{ __html: node.title }}
                  />
                  {node.acf.credentials.map((cred, index) => {
                    return (
                      <div className={teamStyles.cred} key={index}>
                        {cred}
                      </div>
                    );
                  })}
                </Link>
              );
            }
          })}
      </div>
    </>
  );
};
export default TeamSection;
